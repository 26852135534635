import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/approval/content",
    name: "ContentApproval",
    component: () => import("@/modules/approval/content/views/TheContentsApproval.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { layout: "AuthenticatedLayout" },
  },
  {
    path: "/approval/content/:dealId",
    name: "ContentOverview",
    component: () => import("@/modules/approval/content/views/TheContentApprovalOverview.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { layout: "AuthenticatedLayout" },
  },
];
